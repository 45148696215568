import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "documents-detail" }
const _hoisted_2 = { class: "preview" }
const _hoisted_3 = { style: {"width":"100%"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        (_ctx.routeUrl && _ctx.getPdf(_ctx.routeUrl))
          ? (_openBlock(), _createBlock("iframe", {
              key: 0,
              src: _ctx.preViewUrl,
              style: { height: _ctx.currentHeight + 'px', width: '100%' }
            }, null, 12, ["src"]))
          : _createCommentVNode("", true),
        (_ctx.routeUrl && !_ctx.getPdf(_ctx.routeUrl))
          ? (_openBlock(), _createBlock("img", {
              key: 1,
              src: _ctx.preViewUrl,
              style: {"width":"100%"}
            }, null, 8, ["src"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}