
import { defineComponent, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { throwCustomFunction } from '../commons/ErrorHandle'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'ConfirmAddressDetail',
  setup() {
    const route = useRoute()
    // const getUrl = process.env.VUE_APP_BASE_API // /judicial
    const getUrl = process.env.VUE_APP_PROXY_TARGET // http://xxxxxxx
    const routeUrl = route.query.url
    // http://192.168.1.100:8446/judicial/files/2020/110106/confirmAddress/321确认地址书02f9b571992c46b6830d0e49942163fe.pdf
    // https://www.i12368.com/webNew/files/2020/340123/2020111823300000007/2020111823300000011/2020121523300000007/delivery/signPdf/2020111823300000007.pdf
    const preViewUrl = (getUrl as string) + (routeUrl as string)
    const currentHeight = document.documentElement.clientHeight

    const getPdf = (url: string) => {
      const filePath = url
      const index = filePath.lastIndexOf('.')
      const ext = filePath.substr(index + 1)
      if (ext == 'pdf' || ext == 'PDF') {
        return true
      }
      return false
    }
    onMounted(() => {
      ElMessage.success({
        type: 'success',
        message: '操作成功'
      })
      if (!routeUrl) {
        throwCustomFunction('文件加载失败')
      }
    })

    return {
      routeUrl,
      getUrl,
      preViewUrl,
      currentHeight,
      getPdf
    }
  }
})
